<template>
    <div class="">
        <users-list />
    </div>
</template>
  
  <script>
import usersList from "@/views/components/users/UsersList";
import GoodTableSsr from "@/views/table/vue-good-table/GoodTableSsr";
import '@core/scss/vue/libs/vue-good-table.scss';
export default {
    name: "Index",
    components: {
        usersList,
        GoodTableSsr
    }
}
</script>
  
  <style scoped lang="scss">
  </style>
  