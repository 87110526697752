<template>
  <b-card title="Users Table">
    <div class="custom-search d-flex justify-content-end justify-content-sm-start mb-2 mb-sm-0">
      <div class="d-flex align-items-center">
        <b-button id="show-btn" v-ripple.400="'rgba(113, 102, 240, 0.15)'" class="float-right" variant="primary"
          :to="{ name: 'create-user' }">Add User</b-button>
      </div>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">Search</label>
          <b-form-input v-model="params.search" placeholder="Search" type="text" class="d-inline-block"
            debounce="1000" />
        </div>
      </b-form-group>
    </div>

    <!-- table -->
    <vue-good-table mode="remote" :pagination-options="{ enabled: true, }" :totalRows="totalRecords" :rows="rows"
      :columns="columns" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-per-page-change="onPerPageChange" :isLoading.sync="isLoading">
      <template slot="table-row" slot-scope="props">
        <!-- Column: Action -->
        <span v-if="props.column.field === 'action'">
          <b-button variant="secondary" :to="{ name: 'update-user', params: {id: props.row.id}}">
            <feather-icon icon="SettingsIcon" />
          </b-button>
        </span>
        <span v-if="props.column.field === 'password'">
          <b-button variant="secondary" @click="$refs.deleteModel.open(props.row.id)">
            Reset Password
          </b-button>
        </span>
        <span v-if="props.column.field === 'status'">
          <b-form-checkbox @change="event => changeStatus(props.row.id)" :checked="props.row.status == 'Active'" class="z-index-0" switch inline> </b-form-checkbox>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
      <template #emptystate>
        <p class="text-center">
          {{ isOPen ? "Loading..." : "This will show up when there are no rows" }}
        </p>
      </template>
    </vue-good-table>
    <general-modal ref="deleteModel" @result="resetPassword" />
  </b-card>
</template>

  <script>
import { BFormCheckbox, BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown, BCard, BButton } from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import 'prismjs'
import 'prismjs/themes/prism-tomorrow.css'
import Prism from 'vue-prism-component'
import store from '@/store/index'
import UserService from "@/services/UserService";
import Ripple from 'vue-ripple-directive'
import GeneralModal from '../shared/GeneralModal.vue';

export default {
  name: 'UsersList',
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    Prism,
    BDropdownItem,
    BDropdown,
    BCard,
    BButton,
    GeneralModal,
    BFormCheckbox
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      params: {
        page: undefined,
        per_page: undefined,
        sort_keys: undefined,
        sort_dir: undefined,
        search: undefined,
      },
      columns: [
        { label: 'User First Name', field: 'first_name' },
        { label: 'User Last Name', field: 'last_name' },
        { label: 'Email', field: 'email' },
        { label: 'Creation Date', field: 'created_at' },
        { label: 'Gender', field: 'gender' },
        { label: 'Mobile Number', field: 'mobile_number' },
        { label: 'Role', field: 'roles.0.name', sortable: false },
        { label: 'Password', field: 'password' },
        { label: 'Status', field: 'status' },
        { label: 'Edit', field: 'action' },
      ],
      rows: [],
      isLoading: undefined,
      isOPen: true,
      totalRecords: undefined,
      UserService: new UserService(),
    }
  },
  watch: {
    params: {
      handler: function () {
        this.getUsers();
      },
      deep: true
    }
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        this.dir = true
        return this.dir
      }
      this.dir = false
      return this.dir
    },
  },
  created() {
    this.getUsers();
  },
  methods: {
    onPerPageChange(page) {
      this.params.page = page.currentPage;
      this.params.per_page = page.currentPerPage;
    },
    onPageChange(active) {
      this.params.page = active.currentPage;
      this.params.per_page = active.currentPerPage;
    },
    onSortChange(params) {
      const SortKey = typeof params[0].field === 'function' ? "client" : params[0].field;
      this.params.sort_keys = [SortKey];
      this.params.sort_dir = [params[0].type];
    },

    resetPassword(userId) {
      this.isLoading = true;
      this.UserService.resetPassword(userId).then(res => {
      }).catch(err => { })
        .finally(() => {
          this.isLoading = false;
          this.isOPen = false;
        });
    },

    changeStatus(id){
      this.isLoading = true;
      var value = !(this.rows.find(el => el.id == id).status == "Active");
      this.UserService.changeStatus(id, value).then(res => {
        this.rows.find(el => el.id == id).status = value;
        this.getUsers();
      }).catch(err => { })
        .finally(() => {
          this.isLoading = false;
          this.isOPen = false;
        });
    },

    getUsers() {
      this.isLoading = true;
      this.UserService.index(this.params).then(res => {
        this.rows = res.data;
        this.totalRecords = res.total;
      }).catch(err => { })
        .finally(() => {
          this.isLoading = false;
          this.isOPen = false;
        });
    },
  },
}
</script>
  <style>
  .loader-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: wait;
  }

  @media (min-width: 576px) {
    .justify-content-sm-start {
      justify-content: flex-start !important;
    }
  }

  @media (max-width: 576px) {

    th,
    td {
      /* font size */
      font-size: 0.857rem;
      font-weight: 400;
    }
  }

  th,
  td {
    /* text center horizontally */
    vertical-align: middle !important;
    text-align: center;
    line-height: 20px;
  }
  </style>
